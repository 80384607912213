<template>
  <div>
    <vx-card class="mt-8">
      <div class="card-title">
        <h2>Resellers</h2>
      </div>
      <!-- Resellers Table -->
      <div class="common-table">
        <!--Table Select Row-->
        <div class="table-row-select">
          <vs-select
            label="Show Entries"
            v-model="perPage"
            class="show-select"
            autocomplete
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in recordList"
            />
          </vs-select>
        </div>
        <vs-table
          ref="tableData"
          :data="manageReseller"
          search
          :max-items="perPage"
          pagination
        >
          <template slot="thead">
            <vs-th></vs-th>
            <vs-th sort-key="Org_Name">{{ LabelConstant.tableHeaderLabelResellerName }}</vs-th>
            <vs-th sort-key="Org_Name_Display">{{ LabelConstant.tableHeaderLabelDisplayName }}</vs-th>
            <vs-th sort-key="Customer_Count_Paid">{{ LabelConstant.tableHeaderLabelPaid }}</vs-th>
            <vs-th sort-key="Customer_Count_Free_Trial">{{ LabelConstant.tableHeaderLabelFreeTrials }}</vs-th>
            <vs-th sort-key="sortableCreatedDate">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td width="90">
                <div class="table-action">
                  <vs-button
                    color="secondary"
                    :title="LabelConstant.buttonLabelLoginAsReseller"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-lock"
                    class="mr-2 float-left"
                    @click="loginAsOrganization(tr.Id)"
                  ></vs-button>
                  <vs-button
                    @click="(editReseller = true), getOrganizationById(tr.Id)"
                    color="primary"
                    :title="LabelConstant.buttonTitleEdit"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-edit"
                    class="mr-0 float-left"
                  ></vs-button>
                </div>
              </vs-td>
              <vs-td :data="tr.Org_Name">{{ tr.Org_Name }}</vs-td>
              <vs-td :data="tr.Org_Name_Display">{{ tr.Org_Name_Display }}</vs-td>
              <vs-td :data="tr.Customer_Count_Paid">{{ tr.Customer_Count_Paid }}</vs-td>
              <vs-td :data="tr.Customer_Count_Free_Trial">{{ tr.Customer_Count_Free_Trial }}</vs-td>
              <vs-td :data="tr.sortableCreatedDate">{{ tr.Date_Created }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">
          Showing {{ showingDataFrom }} to {{ showingDataTo }} of
          {{ manageReseller.length | tableRecordNumberFormatter }} entries
        </p>
        <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">
          Showing {{ showingDataFrom }} to {{ showingSearchedDataTo }} of
          {{ totalSearchedData | tableRecordNumberFormatter }} entries (filtered
          from {{ manageReseller.length | tableRecordNumberFormatter }} entries)
        </p>
        <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">
          Showing 0 to 0 of 0 entries
        </p>
      </div>
    </vx-card>
    <!-- Edit Reseller -->
    <vs-popup
      class="popup-width close-open"
      title="Edit Reseller"
      :active.sync="editReseller"
    >
      <vs-button
        @click="cancelUpdateOrganization()"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}
      </vs-button>
      <template>
        <form data-vv-scope="editResellerForm">
          <div class="vx-row">
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Company Name"
                v-model="companyName"
                name="companyName"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">
                {{ errors.first("editResellerForm.companyName") }}
              </span>
            </div>
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Legal Name"
                v-model="legalName"
                name="legalName"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">
                {{ errors.first("editResellerForm.legalName") }}
              </span>
            </div>
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Domain Name"
                v-model="domainName"
                name="domainName"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">
                {{ errors.first("editResellerForm.domainName") }}
              </span>
              <p class="mt-1">
                This is your private label address. Use this address for you and
                your customers to access Visual Visitor
              </p>
            </div>
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Actual Domain Name"
                v-model="actualDomainName"
                name="actualDomainName"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">
                {{ errors.first("editResellerForm.actualDomainName") }}
              </span>
              <p class="mt-1"> This is your domain name of your address. This will be used in email communications to your customers. </p>
            </div>
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Main Phone"
                v-model="mainPhone"
                name="mainPhone"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">
                {{ errors.first("editResellerForm.mainPhone") }}
              </span>
            </div>
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Sales Phone"
                v-model="salesPhone"
                name="salesPhone"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">
                {{ errors.first("editResellerForm.salesPhone") }}
              </span>
            </div>
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Support Phone"
                v-model="supportPhone"
                name="supportPhone"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">
                {{ errors.first("editResellerForm.supportPhone") }}
              </span>
            </div>
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="General Email"
                v-model="generalEmail"
                name="generalEmail"
                v-validate="'required|email'"
              />
              <span class="text-danger text-sm">
                {{ errors.first("editResellerForm.generalEmail") }}
              </span>
            </div>
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Sales Email"
                v-model="salseEmail"
                name="salseEmail"
                v-validate="'required|email'"
              />
              <span class="text-danger text-sm">
                {{ errors.first("editResellerForm.salseEmail") }}
              </span>
            </div>
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Support Email"
                v-model="supportEmail"
                name="supportEmail"
                v-validate="'required|email'"
              />
              <span class="text-danger text-sm">
                {{ errors.first("editResellerForm.supportEmail") }}
              </span>
            </div>
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Send As Email"
                v-model="sendAsEmail"
                name="sendAsEmail"
                v-validate="'required|email'"
              />
              <span class="text-danger text-sm">
                {{ errors.first("editResellerForm.sendAsEmail") }}
              </span>
            </div>
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <vs-checkbox class="ml-0 mb-1 mt-8" v-model="isTrustedReseller">Trusted Reseller</vs-checkbox>
            </div>
          </div>
          <!-- Enable new features -->
          <div class="vx-row md:ml-5 ml-2 mt-8">
            <div class="vx-col lg:w-full w-full mb-6">
              <vs-checkbox class="ml-0 my-1" v-model="enableNewFeatures">Enable new features</vs-checkbox>
            </div>
          </div>

          <div class="vx-row md:ml-10 ml-5 md:pl-10" v-if="enableNewFeatures">
            <div class="vx-col lg:w-1/2 w-full mb-6">
              <span class="vs-input--label pr-4 w-full">
                Select the reseller's base plan - their individual account
              </span>
              <v-select
                label="Final_Plan_Name"
                class="w-auto"
                :options="availableBasePlansList"
                :reduce="option=>option.Id"
                v-model="resellerBasePlanId"
                name="resellerBasePlanId"
                v-validate="enableNewFeatures ? 'required' : ''"
              />
              <span class="text-danger text-sm">{{errors.first("editResellerForm.resellerBasePlanId")}}</span>
            </div>
          </div>
          <!--end  Enable new features -->

          <!--Allow resale of standard reseller plan  -->
          <div class="vx-row md:ml-10 ml-5 md:pl-10" v-show="enableNewFeatures">
            <div class="vx-col lg:w-full w-full mb-6">
              <vs-checkbox class="ml-0 my-1" v-model="allowResaleStandardPlan">
                Allow resale of standard reseller plan
              </vs-checkbox>
            </div>
            <!-- 3 Select boxs -->
            <div class="vx-col lg:w-full w-full mb-6" v-if="allowResaleStandardPlan">
              <div class="vx-row ml-5">
                <div class="vx-col lg:w-1/2 w-full mb-6">
                  <span class="vs-input--label pr-4 w-full">
                    Select Standard Plan for Resold Accounts
                  </span>
                  <v-select
                    label="Final_Plan_Name"
                    class="w-auto"
                    :options="availableBasePlansList"
                    :reduce="option=>option.Id"
                    v-model="resellerStandardPlanId"
                    name="resellerStandardPlanId"
                    v-validate="allowResaleStandardPlan ? 'required' : ''"
                  />
                  <span class="text-danger text-sm">{{errors.first("editResellerForm.resellerStandardPlanId")}}</span>
                </div>
              </div>
              <div class="vx-row ml-5">
                <div class="vx-col lg:w-1/2 w-full mb-6">
                  <span class="vs-input--label pr-4 w-full">
                    Select the associated billing plan for new customers
                  </span>
                  <v-select
                    label="Value"
                    class="w-auto"
                    :options="availableResaleBillingPlansList"
                    :reduce="option=>option.Key"
                    v-model="resellerStandardPlanBillingId"
                    name="resellerStandardPlanBillingId"
                    v-validate="allowResaleStandardPlan ? 'required' : ''"
                  />
                  <span class="text-danger text-sm">{{errors.first("editResellerForm.resellerStandardPlanBillingId")}}</span>
                </div>
              </div>
              <div class="vx-row ml-5">
                <div class="vx-col lg:w-1/2 w-full mb-6">
                  <span class="vs-input--label pr-4 w-full">
                    Each additional campaign
                  </span>
                  <v-select
                    label="Value"
                    class="w-auto"
                    :options="availableResaleBillingPlansList"
                    :reduce="option=>option.Key"
                    v-model="resellerStandardPlanExtraCampaignBillingId"
                    name="resellerStandardPlanExtraCampaignBillingId"
                    v-validate="allowResaleStandardPlan ? 'required' : ''"
                  />
                  <span class="text-danger text-sm">{{errors.first("editResellerForm.resellerStandardPlanExtraCampaignBillingId")}}</span>
                </div>
              </div>
            </div>
            <!--end 3 Select boxs -->
          </div>
          <!-- end Allow resale of standard reseller plan  -->

          <!--Allow resale of entry level reseller plan -->
          <div class="vx-row md:ml-10 ml-5 md:pl-10" v-show="enableNewFeatures">
            <div class="vx-col lg:w-full w-full mb-6">
              <vs-checkbox class="ml-0 my-1" v-model="allowResaleEntryLevelPlan">
                Allow resale of entry level reseller plan
              </vs-checkbox>
            </div>
            <!-- 2 Select boxs -->
            <div class="vx-col lg:w-full w-full mb-6" v-if="allowResaleEntryLevelPlan">
              <div class="vx-row ml-5">
                <div class="vx-col lg:w-1/2 w-full mb-6">
                  <span class="vs-input--label pr-4 w-full">Select Entry Level Plan for Resold Accounts</span>
                  <v-select
                    label="Final_Plan_Name"
                    class="w-auto"
                    :options="availableBasePlansList"
                    :reduce="option=>option.Id"
                    v-model="resellerEntryLevelPlanId"
                    name="resellerEntryLevelPlanId"
                    v-validate="allowResaleEntryLevelPlan ? 'required' : ''"
                  />
                  <span class="text-danger text-sm">{{errors.first("editResellerForm.resellerEntryLevelPlanId")}}</span>
                </div>
              </div>
              <div class="vx-row ml-5">
                <div class="vx-col lg:w-1/2 w-full mb-6">
                  <span class="vs-input--label pr-4 w-full">Select the associated billing plan</span>
                  <v-select
                    label="Value"
                    class="w-auto"
                    :options="availableResaleBillingPlansList"
                    :reduce="option=>option.Key"
                    v-model="resellerEntryLevelPlanBillingId"
                    name="resellerEntryLevelPlanBillingId"
                    v-validate="allowResaleEntryLevelPlan ? 'required' : ''"
                  />
                  <span class="text-danger text-sm">{{errors.first("editResellerForm.resellerEntryLevelPlanBillingId")}}</span>
                </div>
              </div>
            </div>
            <!--end 2 Select boxs -->
          </div>
          <!-- end Allow resale of entry level reseller plan  -->

          <!-- resale Consumer Plan -->
          <div class="vx-row md:ml-10 ml-5 md:pl-10" v-show="enableNewFeatures">
            <div class="vx-col lg:w-full w-full mb-6">
              <vs-checkbox class="ml-0 my-1" v-model="allowResaleConsumerPlan">
                Allow resale of consumer plans
              </vs-checkbox>
            </div>
            <div class="vx-col lg:w-full w-full mb-6" v-if="allowResaleConsumerPlan">
              <div class="vx-row ml-5">
                <div class="vx-col lg:w-1/2 w-full mb-6">
                  <span class="vs-input--label pr-4 w-full">Select Consumer Level Plan for Resold Accounts</span>
                  <v-select
                    label="Final_Plan_Name"
                    class="w-auto"
                    :options="availableBasePlansList"
                    :reduce="option=>option.Id"
                    v-model="resellerConsumerPlanId"
                    name="resellerConsumerPlanId"
                    v-validate="allowResaleConsumerPlan ? 'required' : ''"
                  />
                  <span class="text-danger text-sm">{{errors.first("editResellerForm.resellerConsumerPlanId")}}</span>
                </div>
              </div>
              <div class="vx-row ml-5">
                <div class="vx-col lg:w-1/2 w-full mb-6">
                  <span class="vs-input--label pr-4 w-full">Select the associated billing plan</span>
                  <v-select
                    label="Value"
                    class="w-auto"
                    :options="availableResaleBillingPlansList"
                    :reduce="option=>option.Key"
                    v-model="resellerConsumerPlanBillingId"
                    name="resellerConsumerPlanBillingId"
                    v-validate="allowResaleConsumerPlan ? 'required' : ''"
                  />
                  <span class="text-danger text-sm">{{errors.first("editResellerForm.resellerConsumerPlanBillingId")}}</span>
                </div>
              </div>
            </div>
          </div>

          <!--Allow resale of legacy plans-->
          <div class="vx-row md:ml-10 ml-5 md:pl-10" v-show="enableNewFeatures">
            <div class="vx-col lg:w-full w-full mb-6">
              <vs-checkbox class="ml-0 my-1" v-model="allowResaleLegacyPlan">Allow resale of legacy plans</vs-checkbox>
            </div>
            <!-- 1 Select boxs -->
            <div class="vx-col lg:w-full w-full mb-6" v-if="allowResaleLegacyPlan">
              <div class="vx-row ml-5">
                <div class="vx-col lg:w-1/2 w-full mb-6">
                  <span class="vs-input--label pr-4 w-full">Select the associated billing plan</span>
                  <v-select
                    label="Value"
                    class="w-auto"
                    :options="availableResaleBillingPlansList"
                    :reduce="option=>option.Key"
                    v-model="resellerLegacyPlanBillingId"
                    name="resellerLegacyPlanBillingId"
                    v-validate="allowResaleLegacyPlan ? 'required' : ''"
                  />
                  <span class="text-danger text-sm">{{errors.first("editResellerForm.resellerLegacyPlanBillingId")}}</span>
                </div>
              </div>
            </div>
            <!--end 1 Select boxs -->
          </div>
          <!-- end Allow resale of legacy plans  -->
        </form>
      </template>
      <div class="float-right mt-6 sm:w-auto w-full mb-8">
        <vs-button
          color="primary"
          type="filled"
          class="sm:w-auto ml-0 mb-2 sm:mb-0 w-full"
          @click="updateOrganization()"
          >{{ LabelConstant.buttonLabelUpdateReseller }}</vs-button
        >
        <vs-button
          @click="cancelUpdateOrganization()"
          color="grey"
          type="border"
          class="sm:ml-4 ml-0 sm:w-auto w-full grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import store from "../../store/store";

/* Filter */
import DateRangePicker from "../../components/filter/DateRangePicker";
import vSelect from "vue-select";
import { Validator } from "vee-validate";

const dict = {
  custom: {
    companyName: {
      required:"Reseller name must be more than 1 and less than 300 characters.",
    },
    legalName: {
      required:"Reseller name must be more than 1 and less than 300 characters.",
    },
    domainName: {
      required: "Domain name must be more than 1 and less than 300 characters.",
    },
    actualDomainName: {
      required:"An Actual Domain name must be more than 1 and less than 300 characters.",
    },
    mainPhone: {
      required: "Please enter a valid phone number.",
    },
    salesPhone: {
      required: "Please enter a valid phone number.",
    },
    supportPhone: {
      required: "Please enter a valid phone number.",
    },
    generalEmail: {
      required: "Please enter email address.",
      email: "Please enter a valid email address.",
    },
    salseEmail: {
      required: "Please enter email address.",
      email: "Please enter a valid email address.",
    },
    supportEmail: {
      required: "Please enter email address.",
      email: "Please enter a valid email address.",
    },
    sendAsEmail: {
      required: "Please enter email address.",
      email: "Please enter a valid email address.",
    },

    resellerBasePlanId: {
      required: 'Please select plan.'
    },
    resellerStandardPlanId: {
      required: 'Please select plan.'
    },
    resellerStandardPlanBillingId: {
      required: 'Please select plan.'
    },
    resellerStandardPlanExtraCampaignBillingId: {
      required: 'Please select plan.'
    },
    resellerEntryLevelPlanId: {
      required: 'Please select plan.'
    },
    resellerEntryLevelPlanBillingId: {
      required: 'Please select plan.'
    },
    resellerConsumerPlanId: {
      required: 'Please select plan.'
    },
    resellerConsumerPlanBillingId: {
      required: 'Please select plan.'
    },
    resellerLegacyPlanBillingId: {
      required: 'Please select plan.'
    },
  },
};
Validator.localize("en", dict);

export default {
  components: {
    VxCard,
    DateRangePicker,
    vSelect,
  },
  data() {
    return {
      manageReseller: [],

      perPage: 5,
      recordList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],

      editReseller: false,
      resellerId: null,
      companyName: null,
      legalName: null,
      domainName: null,
      actualDomainName: null,
      mainPhone: null,
      salesPhone: null,
      supportPhone: null,
      generalEmail: null,
      salseEmail: null,
      supportEmail: null,
      sendAsEmail: null,

      isTrustedReseller:false,

      enableNewFeatures: false,
      resellerBasePlanId: null,

      allowResaleStandardPlan: false,
      resellerStandardPlanId: null,
      resellerStandardPlanBillingId: null,
      resellerStandardPlanExtraCampaignBillingId: null,

      allowResaleEntryLevelPlan: false,
      resellerEntryLevelPlanId: null,
      resellerEntryLevelPlanBillingId: null,

      allowResaleConsumerPlan: false,
      resellerConsumerPlanId: null,
      resellerConsumerPlanBillingId: null,

      allowResaleLegacyPlan: false,
      resellerLegacyPlanBillingId: null,

      availableBasePlansList: [],
      // availableResalePlansList: [],
      availableResaleBillingPlansList: [],


      editResellerData: null,

      //Dynamic Sidebar Menu switched reseller
      dynamicSidebarMenuItem: [],

      //Dynamic theme
      dynamicTheme: null,
    };
  },
  created() {
    this.getOrganizationList();
    this.getAvailableBasePlans();
    // this.getAvailableResalePlans();
    this.getAvailableResaleBillingPlans();
  },
  methods: {
    //Get list all Reseller
    getOrganizationList() {
      this.$vs.loading();
      this.axios
        .get("/ws/Organization/GetOrganizationList")
        .then((response) => {
          let data = response.data;
          this.manageReseller = data;
          this.manageReseller.sort(this.GetSortOrder("Org_Name", "ASC")); //Pass the attribute to be sorted on
          this.manageReseller.map(function (manipulatedData) {
            manipulatedData.sortableCreatedDate = new Date(
              manipulatedData.Date_Created
            );
            return manipulatedData;
          });
          this.excelData = this.filterExport();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Get single Reseller
    getOrganizationById(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/Organization/GetOrganizationById?id=" + id)
        .then((response) => {
          let data = response.data;
          this.editResellerData = data;
          this.resellerId = data.Id;
          this.companyName = data.Org_Name;
          this.legalName = data.Org_Name_Display;
          this.domainName = data.Domain_Name;
          this.actualDomainName = data.Org_Actual_domain_Name;
          this.mainPhone = data.Org_Phone_Main;
          this.salesPhone = data.Org_Phone_Sales;
          this.supportPhone = data.Org_Phone_Support;
          this.generalEmail = data.Org_Email_General;
          this.salseEmail = data.Org_Email_Sales;
          this.supportEmail = data.Org_Email_Support;
          this.sendAsEmail = data.Email_From_Address;

          this.isTrustedReseller=data.Is_Trusted_Reseller,

          this.enableNewFeatures = data.Enable_New_Features;
          this.resellerBasePlanId = data.Reseller_Base_Plan_Id;

          this.allowResaleStandardPlan = data.Allow_Resale_Standard_Plan;
          this.resellerStandardPlanId = data.Reseller_Standard_Plan_Id;
          this.resellerStandardPlanBillingId = data.Reseller_Standard_Plan_Billing_Id;
          this.resellerStandardPlanExtraCampaignBillingId = data.Reseller_Standard_Plan_Extra_Campaign_Billing_Id;

          this.allowResaleEntryLevelPlan = data.Allow_Resale_Entry_Level_Plan;
          this.resellerEntryLevelPlanId = data.Reseller_Entry_Level_Plan_Id;
          this.resellerEntryLevelPlanBillingId = data.Reseller_Entry_Level_Plan_Billing_Id;

          this.allowResaleConsumerPlan=data.Allow_Resale_Consumer_Plan,
          this.resellerConsumerPlanId=data.Reseller_Consumer_Plan_Id,
          this.resellerConsumerPlanBillingId=data.Reseller_Consumer_Plan_Billing_id,

          this.allowResaleLegacyPlan = data.Allow_Resale_Legacy_Plan;
          this.resellerLegacyPlanBillingId = data.Reseller_Legacy_Plan_Billing_Id;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Cancel update Reseller
    cancelUpdateOrganization() {
      this.editResellerData = null;
      this.resellerId = null;
      this.companyName = null;
      this.legalName = null;
      this.domainName = null;
      this.actualDomainName = null;
      this.mainPhone = null;
      this.salesPhone = null;
      this.supportPhone = null;
      this.generalEmail = null;
      this.salseEmail = null;
      this.supportEmail = null;
      this.sendAsEmail = null;
      this.editReseller = false;

      this.isTrustedReseller=false;

      this.enableNewFeatures = false;
      this.resellerBasePlanId = null;

      this.allowResaleStandardPlan = false;
      this.resellerStandardPlanId = null;
      this.resellerStandardPlanBillingId = null;
      this.resellerStandardPlanExtraCampaignBillingId = null;

      this.allowResaleEntryLevelPlan = false;
      this.resellerEntryLevelPlanId = null;
      this.resellerEntryLevelPlanBillingId = null;

      this.allowResaleConsumerPlan= false;
      this.resellerConsumerPlanId= null;
      this.resellerConsumerPlanBillingId= null;

      this.allowResaleLegacyPlan = false;
      this.resellerLegacyPlanBillingId = null;

      setTimeout(() => {
        this.errors.clear("editResellerForm");
      }, 20);
    },
    //Update Reseller
    updateOrganization() {
      this.$validator.validateAll("editResellerForm").then((result) => {
        if (result) {
          let updateOrganizationParams = {
            Id: this.resellerId,
            Org_Name: this.companyName,
            Org_Name_Display: this.legalName,
            Domain_Name: this.domainName,
            Org_Actual_domain_Name: this.actualDomainName,
            Org_Phone_Main: this.mainPhone,
            Org_Phone_Sales: this.salesPhone,
            Org_Phone_Support: this.supportPhone,
            Org_Email_General: this.generalEmail,
            Org_Email_Sales: this.salseEmail,
            Org_Email_Support: this.supportEmail,
            Email_From_Address: this.sendAsEmail,
            is_Default_Org: this.editResellerData.is_Default_Org,
            Date_Created: this.editResellerData.Date_Created,
            Date_Updated: this.editResellerData.Date_Updated,
            IsDeleted: this.editResellerData.IsDeleted,
            IsActive: this.editResellerData.IsActive,
            Org_Image_Current: this.editResellerData.Org_Image_Current,
            Org_Image_File_Ext: this.editResellerData.Org_Image_File_Ext,
            Org_Main_Temp_Image_File:this.editResellerData.Org_Main_Temp_Image_File,
            Customer_Id: this.editResellerData.Customer_Id,
            Current_Theme: this.editResellerData.Current_Theme,
            Anonymous_Visitor_Id: this.editResellerData.Anonymous_Visitor_Id,
            Call_Tracking: this.editResellerData.Call_Tracking,
            Zapier_Integration: this.editResellerData.Zapier_Integration,
            Client_Email: this.editResellerData.Client_Email,
            DashboardThemeV2: this.editResellerData.DashboardThemeV2,
            Enable_New_Features: this.enableNewFeatures,
            Reseller_Base_Plan_Id: this.resellerBasePlanId,
            Allow_Resale_Standard_Plan: this.allowResaleStandardPlan,
            Reseller_Standard_Plan_Id: this.resellerStandardPlanId,
            Reseller_Standard_Plan_Billing_Id: this.resellerStandardPlanBillingId,
            Reseller_Standard_Plan_Extra_Campaign_Billing_Id: this.resellerStandardPlanExtraCampaignBillingId,
            Allow_Resale_Entry_Level_Plan: this.allowResaleEntryLevelPlan,
            Reseller_Entry_Level_Plan_Id: this.resellerEntryLevelPlanId,
            Reseller_Entry_Level_Plan_Billing_Id: this.resellerEntryLevelPlanBillingId,
            Allow_Resale_Legacy_Plan: this.allowResaleLegacyPlan,
            Reseller_Legacy_Plan_Billing_Id: this.resellerLegacyPlanBillingId,
            Is_Trusted_Reseller: this.isTrustedReseller,
            Allow_Resale_Consumer_Plan: this.allowResaleConsumerPlan,
            Reseller_Consumer_Plan_Id: this.resellerConsumerPlanId,
            Reseller_Consumer_Plan_Billing_id: this.resellerConsumerPlanBillingId,
          };
          this.$vs.loading();
          this.axios.post("/ws/Organization/UpdateOrganization",updateOrganizationParams)
            .then(() => {
              this.cancelUpdateOrganization();
              this.getOrganizationList();
              this.$vs.notify({
                title: "Success",
                text: "Reseller modification successful.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        }
      });
    },
    //Login as Reseller in new browser tab
    loginAsOrganization(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/Organization/LoginAsOrganization?id=" + id)
        .then((response) => {
          let data = response.data;

          //Get array of tokens from local storage
          let VVtoken = localStorage.getItem("VVtoken");
          let token = JSON.parse(VVtoken);
          let currentUid = this.$route.params.id;

          //Use authentication token of the newly switched Reseller to get list of dynamic menu items
          this.axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.token;
          let url = "Account/GetInterfaceOptions";
          this.axios
            .get(url)
            .then((response) => {
              //Revert authentication token of current logged user
              this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + token[currentUid].token;
              let menuData = response.data;
              this.dynamicSidebarMenuItem = menuData.MenuItems;
              this.dynamicTheme = menuData.DashboardThemeV2;
              this.dynamicTheme.tempActionButtonColor = this.RGBToHex(
                menuData.DashboardThemeV2.ActionButtonColor
              );
              this.dynamicTheme.tempChartColor1 = this.RGBToHex(
                menuData.DashboardThemeV2.ChartColor1
              );
              this.dynamicTheme.tempChartColor2 = this.RGBToHex(
                menuData.DashboardThemeV2.ChartColor2
              );
              this.dynamicTheme.tempChartColor3 = this.RGBToHex(
                menuData.DashboardThemeV2.ChartColor3
              );
              this.dynamicTheme.tempChartColor4 = this.RGBToHex(
                menuData.DashboardThemeV2.ChartColor4
              );
              this.dynamicTheme.tempChartColor5 = this.RGBToHex(
                menuData.DashboardThemeV2.ChartColor5
              );
              this.dynamicTheme.tempChartColor6 = this.RGBToHex(
                menuData.DashboardThemeV2.ChartColor6
              );
              this.dynamicTheme.tempChartColor7 = this.RGBToHex(
                menuData.DashboardThemeV2.ChartColor7
              );
              this.dynamicTheme.tempChartColor8 = this.RGBToHex(
                menuData.DashboardThemeV2.ChartColor8
              );
              this.dynamicTheme.tempFirstColor = this.RGBToHex(
                menuData.DashboardThemeV2.FirstColor
              );
              this.dynamicTheme.tempSecondColor = this.RGBToHex(
                menuData.DashboardThemeV2.SecondColor
              );
              this.dynamicTheme.tempThirdColor = this.RGBToHex(
                menuData.DashboardThemeV2.ThirdColor
              );
              this.dynamicTheme.tempFourthColor = this.RGBToHex(
                menuData.DashboardThemeV2.FourthColor
              );
              this.dynamicTheme.tempNavbarColor = this.RGBToHex(
                menuData.DashboardThemeV2.NavbarColor
              );
              this.dynamicTheme.tempThemeColor = this.RGBToHex(
                menuData.DashboardThemeV2.ThemeColor
              );

              let redirectDefaultUrl = this.getRedirectDefaultUrl(
                menuData.DefaultHomePage
              );
              //Checked loged in or not
              for (let i = 1; i < token.length; i++) {
                if (token[i].id == id) {
                  if (!token[i].isLogedIn && !token[i].token) {
                    token[i].token = data.token;
                    token[i].isLogedIn = true;
                    token[i].menuItems = this.dynamicSidebarMenuItem;
                    token[i].themeDetail = this.dynamicTheme;
                    token[i].isReseller = menuData.Reseller;
                    token[i].isActiveFreeTrial = menuData.IsActiveFreeTrial;
                    token[i].isExpiredFreeTrial = menuData.FreeTrialIsExpired;
                    token[i].resellerIsLegacyOnly = menuData.Reseller_Is_Legacy_Only;
                    token[i].ShowContactReport = menuData.ShowContactReport;
                    token[i].userDetails = menuData.UserInformation;
                    token[i].maxLocalNumbers = menuData.MaxLocalNumbers;
                    token[i].DefaultHomePage = menuData.DefaultHomePage;
                    token[i].showBillingV2 = menuData.ShowBillingV2;
                    token[i].showGuidedTour = menuData.ShowGuidedTour;
                    token[i].screenItems = menuData.ScreenItems;
                    token[i].isConsumerPlan = menuData.Is_Consumer_Plan;
                    token[i].isReportSettingsReadOnly = menuData.Is_Report_Settings_ReadOnly;
                    token[i].enableZapierIntegration = menuData.EnableZapierIntegration;
                    token[i].isResellerPrimaryAccount = menuData.Is_Reseller_Primary_Account;
                    token[i].isResellerSubaccount = menuData.Is_Reseller_Subaccount;
                    localStorage.setItem("VVtoken", JSON.stringify(token));
                    store.dispatch("updateAuthToken", token);

                    window.open(redirectDefaultUrl + i, "_blank");
                    this.$vs.loading.close();
                    return false;
                  } else {
                    window.open(redirectDefaultUrl + i, "_blank");
                    this.$vs.loading.close();
                    return false;
                  }
                }
              }
              //Push new brand login details array
              token.push({
                id: id,
                token: data.token,
                refreshToken: null,
                isLogedIn: true,
                menuItems: this.dynamicSidebarMenuItem,
                themeDetail: this.dynamicTheme,
                isReseller: menuData.Reseller,
                isActiveFreeTrial: menuData.IsActiveFreeTrial,
                isExpiredFreeTrial: menuData.FreeTrialIsExpired,
                resellerIsLegacyOnly: menuData.Reseller_Is_Legacy_Only,
                ShowContactReport: menuData.ShowContactReport,
                userDetails: menuData.UserInformation,
                maxLocalNumbers: menuData.MaxLocalNumbers,
                DefaultHomePage: menuData.DefaultHomePage,
                showBillingV2: menuData.ShowBillingV2,
                showGuidedTour: menuData.ShowGuidedTour,
                screenItems : menuData.ScreenItems,
                isConsumerPlan : menuData.Is_Consumer_Plan,
                isReportSettingsReadOnly: menuData.Is_Report_Settings_ReadOnly,
                enableZapierIntegration: menuData.EnableZapierIntegration,
                isResellerPrimaryAccount: menuData.Is_Reseller_Primary_Account,
                isResellerSubaccount: menuData.Is_Reseller_Subaccount
              });

              //Update local storage and vue storage with new brand login
              localStorage.setItem("VVtoken", JSON.stringify(token));
              store.dispatch("updateAuthToken", token);

              //Redirect brand login to new tab window
              let uid = token.length - 1;
              window.open(redirectDefaultUrl + uid, "_blank");

              this.$vs.loading.close();
            })
            .catch(() => {
              this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + token[currentUid].token;
              // this.showError(e);
              this.logout();
            });
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Error",
            text: e.response.data,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getAvailableBasePlans(){
      this.$vs.loading();
      this.axios.get("/ws/Organization/GetAvailableBasePlans").then((response) => {
        let data = response.data;
        this.availableBasePlansList = data;
        this.availableBasePlansList.map(function (manipulatedData) {
          manipulatedData.Final_Plan_Name = manipulatedData.Plan_Version_Id+'-'+manipulatedData.Plan_Name
          return manipulatedData;
        });
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    // getAvailableResalePlans(){
    //   this.$vs.loading();
    //   this.axios.get("/ws/Organization/GetAvailableResalePlans").then((response) => {
    //     let data = response.data;
    //     this.availableResalePlansList = data;
    //     this.$vs.loading.close();
    //   }).catch((e) => {
    //     this.$vs.loading.close();
    //     this.showError(e);
    //   });
    // },
    getAvailableResaleBillingPlans(){
      this.$vs.loading();
      this.axios.get("/ws/Organization/GetAvailableResaleBillingPlans").then((response) => {
        let data = response.data;
        this.availableResaleBillingPlansList = data;
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.showError(e);
      });
    }
  },
  computed: {
    /**
     * Showing Entries details
     * isTableRecordEntriesDisplay()
     * isSearch()
     * showingDataFrom()
     * showingDataTo()
     * totalSearchedData()
     * showingSearchedDataTo()
     *  */
    isTableRecordEntriesDisplay() {
      if (this.manageReseller.length > 0) {
        if (this.$refs.tableData.datax.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isSearch: function () {
      if (this.manageReseller.length > 0) {
        if (
          this.$refs.tableData.searchx == null ||
          this.$refs.tableData.searchx == ""
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    showingDataFrom: function () {
      if (this.manageReseller.length > 0) {
        return (this.$refs.tableData.currentx - 1) * this.perPage + 1;
      }
    },
    showingDataTo: function () {
      if (this.manageReseller.length > 0) {
        let totalPages = this.$refs.tableData.getTotalPages;
        if (totalPages == this.$refs.tableData.currentx) {
          return (
            (this.$refs.tableData.currentx - 1) * this.perPage +
            this.$refs.tableData.datax.length
          );
        } else {
          return this.$refs.tableData.currentx * this.perPage;
        }
      }
    },
    totalSearchedData: function () {
      if (this.manageReseller.length > 0) {
        return this.$refs.tableData.queriedResults.length;
      }
    },
    showingSearchedDataTo: function () {
      if (this.manageReseller.length > 0) {
        let totalPages = this.$refs.tableData.getTotalPagesSearch;
        if (totalPages == this.$refs.tableData.currentx) {
          return (
            (this.$refs.tableData.currentx - 1) * this.perPage +
            this.$refs.tableData.datax.length
          );
        } else {
          return this.$refs.tableData.currentx * this.perPage;
        }
      }
    },
  },
};
</script>
